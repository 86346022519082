.contentHelp {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.contentInfo {
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 20px;
  background: transparent;
  border: none;
  text-align: left;
  align-items: center;
}

.title {
  font-weight: 700;
  font-size: 18px;
}

.variant {
  font-weight: 500;
  font-size: 14px;
  color: #999999;
}

@media (max-width: 640px) {
  .contentHelp {
    flex-direction: column;
  }

  .buttonHelp {
    width: 100%;
  }

  .contentInfo {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
