/* Scrollbar Styles */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: black;
}

::-webkit-scrollbar-thumb {
  background-color: #ffd000;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ccaa00;
}

.back-arrow {
  position: absolute;
  top: 20px;
  left: 20px;
}

a.terms-link {
  color: #ffd000;
  margin: 0 0 10px 0;
}

.authLabel {
  text-align: start;
}

#loginPage {
  min-height: 100vh;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#loginPage .loginContainer {
  width: 400px;
}

#loginPage img {
  margin-bottom: 1.5rem;
}

#loginPage .primaryBtn {
  margin-bottom: 1rem;
}

a.authLink {
  color: #ffd000;
}

button.primaryBtn {
  background-color: #ffd000;
  color: black;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}

button.primaryBtn:hover {
  background-color: #ccaa00;
  color: black;
}

button.primaryBtn:active {
  background-color: #ffd000 !important;
  color: black !important;
}

button.secondaryBtn {
  background-color: black;
  color: #ffd000;
  border: 1px solid #ffd000;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  width: 100%;
}

button.secondaryBtn:hover {
  background-color: black !important;
  color: #ccaa00 !important;
  border: 1px solid #ccaa00 !important;
}

button.whiteBtn {
  background-color: white;
  color: black;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  width: 100%;
}

button.whiteBtn:hover {
  background-color: white;
}

button.whiteSecondaryBtn {
  background-color: black;
  color: white;
  border: 1px solid #ffd000;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  width: 100%;
}

button.whiteSecondaryBtn:hover {
  background-color: black;
  border: 1px solid #ffd000;
}

button.whiteSecondaryBtn:active {
  background-color: black !important;
  border: 1px solid #ffd000 !important;
}

header {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navbarItem {
  color: white;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  margin-right: 2rem;
  cursor: pointer;
  text-decoration: none;
}

header svg {
  width: 40px;
  height: 40px;
  color: #ffd000;
}

#sidebar {
  background-color: #111111;
  position: absolute;
  right: 0px;
  height: 100vh;
  width: 75%;
  z-index: 999;
}

#sidebarContainer {
  padding: 1rem;
}

#sidebar svg {
  color: #ffd000;
  width: 35px;
  height: 35px;
  margin-bottom: 2rem;
}

#sidebar .navbarItem {
  color: white;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  margin-right: 0px;
  cursor: pointer;
  margin-bottom: 2rem;
  display: block;
}

.page {
  padding: 1rem 2rem;
  min-height: 100vh;
}

h1 {
  color: white !important;
  font-size: 32px !important;
  font-weight: 800 !important;
}

h2 {
  color: white !important;
  font-size: 32px !important;
  font-weight: 700 !important;
  margin-top: 0px !important;
}

h2 svg {
  color: #ffd000;
  height: 40px;
  width: 40px;
  margin-top: -5px;
  margin-left: -10px;
  cursor: pointer;
}

h3 {
  color: white !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  margin-top: 0px !important;
  margin-bottom: 0.5rem !important;
}

p {
  color: white;
}

p.label {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 0px;
}

.bold-text {
  font-weight: bold;
}

select {
  background-color: #333;
  color: #fff;
  border: 1px solid #666;
  padding: 10px;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  padding-right: 40px;
}

.select-container {
  position: relative;
  display: inline-block;
  width: fit-content;
}

.select-container::after {
  content: '';
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
  transform: translateY(-50%);
}

.mainInput {
  width: 100%;
  box-sizing: border-box;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-bottom: 0.5rem;
  border: 1px solid white !important;
  border-radius: 10px;
  font-size: 16px;
  outline: none;
  color: white !important;
  background-color: black !important;
}

.mainInput::placeholder {
  color: #999999 !important;
}

.mainInput:focus {
  border-color: #ffd000 !important;
  box-shadow: none !important;
}

.mainInput option {
  color: white;
}

.accordion-item {
  border: 1px solid white !important;
  background-color: black !important;
}

.accordion {
  --bs-accordion-border-radius: 10px !important;
  --bs-accordion-btn-bg: transparent !important;
  --bs-accordion-border-color: transparent !important;
}

.accordion-button {
  color: white !important;
  font-weight: bold;
}

.accordion-button:focus {
  border-color: transparent !important;
  box-shadow: none !important;
  color: white !important;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
}

.accordion-button::after {
  margin-bottom: -0.5rem !important;
}

.accordion-button:not(.collapsed)::after {
  margin-top: -1rem !important;
}

.accordion {
  --bs-accordion-btn-icon: url('https://res.cloudinary.com/da3qtg7t5/image/upload/v1690518444/General%20use/Vector_ks6abz.svg') !important;
  --bs-accordion-btn-active-icon: url('https://res.cloudinary.com/da3qtg7t5/image/upload/v1690518444/General%20use/Vector_ks6abz.svg') !important;
}

h2.accordion-header {
  margin-bottom: 0px !important;
}

.accordion-body {
  padding-top: 0px !important;
  color: white !important;
}

footer p {
  color: white;
  margin: 0px;
}

footer svg {
  width: 20px;
  height: 20px;
}

#swal2-html-container {
  color: white;
}

.swal2-confirm {
  color: #111111 !important;
}

div:where(.swal2-icon).swal2-warning {
  color: #ffd000 !important;
  border-color: #ffd000 !important;
}

div:where(.swal2-icon).swal2-question {
  color: #ffd000 !important;
  border-color: #ffd000 !important;
}

.customerListItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #000000;
  margin-bottom: 0.5rem;
  border-radius: 10px;
  padding: 1rem 1rem;
  cursor: pointer;
}

.customerListItemContainer p {
  margin: 0px;
}

.productContainer {
  width: 200px;
}

.productContainer img {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 0.5rem;
}

.productContainer .productName {
  font-size: 14px;
  margin-bottom: 0px;
}

.productContainer .productBrand {
  font-size: 14px;
  color: #aaaaaa;
  margin-bottom: 0px;
}

.productContainer .productPrice {
  margin-bottom: 0px;
}

.productContainer .productLink {
  background-color: #ffd000;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  border-radius: 10px;
}

.productContainer .productLink svg {
  width: 25px;
  height: 25px;
  color: black;
}

.tabBar {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

button.tabButton {
  background-color: black;
  color: #ffd000;
  border: 1px solid #ffd000;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  margin-right: 0.5rem;
}

button.tabButton:hover {
  background-color: black;
  border: 1px solid #ffd000;
  color: #ffd000;
}

button.tabButton.active {
  background-color: #ffd000;
  color: black;
  border: 1px solid #ffd000;
}

button.tabButton:active {
  background-color: #ffd000 !important;
  color: black !important;
  border: 1px solid #ffd000 !important;
}

button.subTabButton {
  background-color: black;
  color: #ccaa00;
  border: 1px solid #ccaa00;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  margin-right: 0.5rem;
}

button.subTabButton:hover {
  background-color: black;
  border: 1px solid #ccaa00;
  color: #ccaa00;
}

button.subTabButton.active {
  background-color: #ffd000;
  color: black;
  border: 1px solid #ffd000;
}

.css-b62m3t-container {
  width: '90%' !important;
}

.css-13cymwt-control {
  background-color: black !important;
  border-radius: 10px !important;
}

.css-t3ipsp-control {
  background-color: black !important;
  border-radius: 10px !important;
}

.css-1dimb5e-singleValue {
  color: white !important;
}

.filtersContainer {
  padding: 1rem;
}

.checkoutProductImageContainer {
  width: 50%;
  background-color: #ffffff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height: 150px;
}

.checkoutProductImage {
  max-height: 100%;
  max-width: -webkit-fill-available;
}

#productGrid {
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: dense;
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 25px;
  grid-template-columns: repeat(4, 1fr);
}

.productImageContainer {
  background-color: #ffffff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  overflow: hidden;
}

.productImage {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.productContent {
  padding: 8px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.productName {
  font-weight: 600;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  position: relative;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  box-orient: vertical;
}

.productDeliveryDate {
  font-size: 14px !important;
  margin: 0.5rem 0;
  color: #f8d640;
}

.product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-row: span 1;
  grid-column: span 1;
  border: 1px solid #f8e697;
  border-radius: 10px;
  max-height: 650px;
  overflow: hidden;
  background-color: #000;
  color: #fff;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.product:hover {
  border: 1px solid #ffd000;
  background-color: #111111;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.product p {
  margin: 0;
  font-size: 18px;
}

.product svg {
  color: #ffd000;
  margin: 2px 3px;
}

.productPrice {
  margin: 0.5rem 0;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}

.productRRP {
  text-decoration: line-through;
  color: #b3291f;
  display: inline-flex;
  align-items: center;
}

.productDiscountedPrice {
  display: inline-flex;
  align-items: center;
}

.productPriceSymbol,
.productPriceWhole {
  vertical-align: middle;
}

.productPriceSymbol {
  margin-right: 0.1rem;
}

.priceMessage {
  font-size: 16px !important;
  color: #ffffff;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  text-align: center;
}

img#banner {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.flexRowSpaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cardHeader {
  font-weight: bold;
  font-size: 20px;
}

.removeCartItemIcon {
  color: #ffd000;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-top: 7.5px;
}

div.avatar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

img.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.react-horizontal-scrolling-menu--wrapper ::-webkit-scrollbar {
  display: none;
}

#dashboardPage .product {
  cursor: pointer;
}

.modal-content {
  background-color: #222222 !important;
  padding: 1rem !important;
  border-radius: 10px !important;
  padding-bottom: 1.5rem !important;
}

.modal-content svg {
  color: #ffd000;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.modalHeader {
  font-weight: bold;
  font-size: 20px;
}

#productModal .productImageCol {
  width: 39%;
}

#productModal .productImageContainer {
  border-radius: 10px;
  width: 100% !important;
  margin-bottom: 0.5rem;
}

#productModalContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

#productModalContainer .productImageContainer {
  width: 39%;
}

#productModalContainer .productInfoContainer {
  width: 59%;
  padding-bottom: 0px !important;
}

#productModalContainer .productName {
  margin-bottom: 0px !important;
}

#productModalContainer a {
  color: #ffd000;
}

.productModalHeader {
  font-weight: bold;
  margin-bottom: 0px;
}

.productModalDetail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #333333;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.productModalDetail p {
  margin-bottom: 0px;
}

.productModalFeature {
  margin-bottom: 0.5rem;
  color: white;
}

.productModalWhatsInTheBox {
  margin-bottom: 0.5rem;
  color: white;
}

.productModalReview {
  border-bottom: 1px solid #333333;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.productModalReview a {
  color: #ffd000;
}

.productModalReview a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: white !important;
}

.productModalReviewRating {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.productModalReviewRating p {
  margin-bottom: 0px;
}

.productModalReviewRating svg {
  width: 20px;
  height: 20px;
  margin-left: 0.25rem;
  margin-right: 0.5rem;
}

.productModalReviewDate {
  margin-bottom: 0.5rem;
}

.productModalReviewBody {
  margin-bottom: 0px;
}

.productModalVariant {
  margin-right: 0.5rem;
  border-radius: 10px;
  padding: 1rem;
  border: 1px solid #333333;
  margin-bottom: 1rem;
  cursor: pointer;
  width: max-content;
}

.productModalVariant.active {
  border: 1px solid #ffd000;
  cursor: default;
}

.productModalVariant p {
  margin-bottom: 0px;
}

.productModalImageContainer {
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  margin-right: 0.5rem;
  cursor: pointer;
}

.driverCapacityItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  color: #ffd000;
  border: 1px solid #ffd000;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 40px;
}

.driverCapacityItem p {
  margin-bottom: 0px;
}

.driverCapacityItem svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.rbc-calendar {
  color: white;
}

.rbc-btn-group button,
.rbc-btn-group button:active {
  background-color: black !important;
}

.rbc-toolbar-label {
  text-transform: capitalize;
  color: white;
  font-weight: bold;
}

.rbc-toolbar button {
  border-radius: 10px !important;
  color: white !important;
  font-weight: 500;
}

.rbc-toolbar button:hover {
  background-color: black !important;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-color: white !important;
  color: black !important;
}

.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0px !important;
}

.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rbc-off-range-bg {
  background: black !important;
  color: white !important;
}

.rbc-today {
  background-color: #222222 !important;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: #ffd000 !important;
  color: black !important;
}

.rbc-button-link {
  color: white !important;
}

.rbc-month-view {
  border-radius: 10px;
}

.rbc-row-bg {
  border-radius: 10px;
}

.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  border-left: 1px solid white;
}

.calendarEventModalTraveler {
  padding-left: 1rem;
  padding-right: 1rem;
  height: 40px;
  border: 1px solid #ffd000;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.calendarEventModalTraveler p {
  margin-bottom: 0px;
  color: #ffd000;
  font-weight: 600;
}

.calendarEventModalTraveler svg {
  cursor: pointer;
  color: #ffd000;
  width: 25px;
  height: 25px;
}

.progress-bar-content {
  position: relative;
  justify-content: space-between;
  width: 100%;
  height: 25px;
  background-color: #ccc !important;
  border-radius: 10px;
}

.progress-tracker {
  height: 100%;
  background-color: #007bff;
  border-radius: 10px;
  width: 0;
  position: absolute;
  top: 0px;
  left: 0;
  transition: width 0.3s ease;
}

.progress-control {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.circle-content {
  position: relative;
  top: 0px;
  display: flex;
  justify-content: space-between;
}

.circle-control {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: white;
  cursor: pointer;
}

.labels-control {
  display: grid;
  grid-template-columns: repeat(5, 1fr) auto;
  width: 100%;
}

.labels-control p {
  width: 70%;
  text-align: left;
}

.labels-control p:last-child {
  width: 100%;
}

/* !IMPORTANT: This are media querys for tablet only */
@media screen and (max-width: 960px) {
  header,
  .page {
    padding: 1rem 1rem;
  }

  h1,
  h2 {
    font-size: 24px !important;
  }

  #productGrid {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  .progress-bar-content {
    width: fit-content;
    position: relative;
    justify-content: space-between;
    height: 100%;
    background-color: #ccc !important;
    border-radius: 10px;
  }

  .progress-bar {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 100%;
    background-color: #ccc !important;
    border-radius: 10px;
  }

  .progress-tracker {
    width: 100%;
    background-color: #007bff;
    border-radius: 10px;
    height: 0;
    position: absolute;
    bottom: 0;
    left: 0px;
    transition: height 0.3s ease;
  }

  .progress-control {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .circle-content {
    position: relative;
    top: 0px;
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
  }

  .circle-control {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background-color: white;
    cursor: pointer;
  }

  .circle-control.highlighted {
    background-color: #007bff;
    border: 1px solid white;
  }

  .labels-control {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    gap: 30px;
  }

  .labels-control p {
    margin-bottom: 0;
  }
}

/* !IMPORTANT: This are media querys for mobile only */
@media only screen and (max-width: 768px) {
  #loginPage .loginContainer {
    width: 90%;
  }

  #comingSoonPage img {
    width: 100% !important;
    height: auto !important;
  }

  #productGrid {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  #productModal .productImageCol,
  #productModalContainer .productImageContainer {
    width: 100%;
  }

  #productModalContainer .productInfoContainer {
    width: 100%;
    padding: 0px !important;
    padding-top: 8px !important;
    margin-bottom: 1rem;
  }

  .rbc-btn-group,
  .rbc-toolbar-label {
    margin-bottom: 1rem;
  }
}
