.img-container {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.img-row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.25rem;
  overflow-y: auto;
  height: 500px;
}

.img-overlay {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background-color: #f5f5f5;
  border-radius: 4px;
  max-height: 500px;
  overflow: hidden;
  width: 100%;
}

.img-overlay-item {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.img-row-item {
  width: 100%;
  height: 75px;
  cursor: pointer;
  object-fit: fill;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}

.img-row-item:hover,
.img-row-item:focus,
.img-row-item.active {
  border-color: black;
}

@media (max-width: 960px) {
  .img-container {
    flex-direction: column-reverse;
    align-items: center;
    margin: 12px 0;
  }

  .img-row {
    flex-direction: row;
    height: auto;
    overflow-x: auto;
  }

  .img-row-item {
    width: 200px;
    height: 100px;
  }

  .img-overlay {
    height: 350px;
  }
}
