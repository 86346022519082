.table-container {
  width: 100%;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #121212;
  border-radius: 8px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

thead {
  background-color: #343a40;
}

th,
td {
  color: #e0e0e0;
  padding: 0.75rem;
  border: 1px solid #444;
  text-align: left;
}

th {
  background-color: #3a3a3a;
  color: #ffffff;
}

tbody tr:nth-child(odd) {
  background-color: #1a1a1a;
}

tbody tr:hover {
  background-color: #333;
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #444;
    margin-bottom: 1rem;
  }

  td {
    border: none;
    border-bottom: 1px solid #555;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: left;
    overflow: hidden;
  }

  td:before {
    color: #e0e0e0;
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    text-align: left;
    font-weight: bold;
    content: attr(data-title);
  }
}
