.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin-top: 1rem;
}

.pagination li {
  margin: 0 5px;
}

.pagination li a {
  text-decoration: none;
  color: #fff !important;
  padding: 8px 16px;
  border: 1px solid #666;
  border-radius: 4px;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.pagination li a:hover {
  background-color: #ffd000;
  color: #333 !important;
}

.pagination .active a {
  background-color: #ffd000;
  border-color: #ffd000;
  color: #333 !important;
}

.pagination .disabled a {
  color: #888 !important;
  cursor: not-allowed;
}

@media screen and (max-width: 800px) {
  .pagination {
    flex-direction: column;
    align-items: center;
  }

  .pagination li {
    margin: 0.75rem 0;
  }

  .pagination li a {
    width: 100%;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
  }
}
