.detail-container {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.top-section {
  display: flex;
  justify-content: center;
}

.bottom-section {
  width: 100%;
  margin-top: 2rem;
}

.price-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.section-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  gap: 0.5rem;
}

.purchase-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.price-section {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.image-gallery {
  flex: 1;
  max-width: 40%;
}

.image-gallery img {
  border-radius: 4px;
}

.product-title {
  font-size: 1.5rem !important;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.section-title {
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
}

.discount-percentage {
  color: #cc0c39;
  font-size: 1.5rem;
  font-weight: 700;
  margin-right: 0.5rem;
}

.discount-price {
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
  margin-right: 0.5rem;
}

.original-price-title {
  color: #565959;
  font-size: 0.75rem;
  font-weight: 400;
}

.original-price {
  color: #565959;
  font-size: 0.75rem;
  font-weight: 400;
  text-decoration: line-through;
}

.product-details {
  flex: 2;
  margin: 0 20px;
}

.purchase-options {
  height: 100%;
  flex: 1;
  padding: 20px;
  border: 1px solid #141414;
  border-radius: 4px;
  background-color: #141414;
}

.delivery-date {
  font-size: 0.75rem;
  font-weight: 400;
}

.purchase-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.product-specifications,
.product-reviews {
  width: 100%;
  padding: 1rem;
  border-radius: 4px;
}

.product-specifications-item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e5e5e5;
}

.product-specifications-item:last-child {
  border-bottom: none;
}

.product-specifications-item p {
  margin: 0;
  font-size: 1rem;
  color: white;
}

.product-reviews {
  margin-top: 2rem;
}

.product-review-item {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e5e5e5;
}

.product-review-item:last-child {
  border-bottom: none;
}

.product-review-item a {
  color: #007185;
  font-weight: bold;
  text-decoration: none;
}

.product-review-rating {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  gap: 0.25rem;
}

.product-review-rating p {
  margin: 0;
  font-size: 1rem;
}

.product-review-rating svg {
  color: #ff9900;
  width: 16px;
  height: 16px;
}

.product-review-date {
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.product-review-body {
  font-size: 1rem;
  margin-top: 0.5rem;
}

.product-review-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.product-review-user {
  color: #007185;
  font-weight: bold;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #ccc;
}

.product-recommendations {
  margin-top: 2rem;
}

.recommendations-carousel {
  display: flex;
  overflow-x: auto;
  gap: 1rem;
  padding-bottom: 0.5rem;
}

.recommendation-item {
  flex: 0 0 auto;
  scroll-snap-align: start;
  width: 200px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
}

@media screen and (max-width: 960px) {
  .top-section {
    flex-direction: column;
  }

  .image-gallery {
    max-width: 100%;
    padding-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .recommendation-item {
    width: 150px;
  }
}
