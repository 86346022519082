.contentTab {
  display: flex;
  gap: 10px;
}

.contentTab button {
  background-color: black;
  color: #ffd000;
  border: 1px solid #ffd000;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  margin-right: 0px;
  padding-left: 12px;
  padding-right: 12px;
}

.tabActive {
  background: #ffd000 !important;
  color: black !important;
  transition: all 0.5s;
}
