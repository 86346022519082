header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#logo {
  width: auto;
  height: 50px;
}

#hamburger {
  display: none;
}

#navbarItems {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 960px) {
  header,
  .page {
    padding: 1rem 1rem;
  }

  #navbarItems {
    display: none !important;
  }

  #hamburger {
    display: block;
  }
}
