/* StickyPopup.css */

/* Container Styles */
.sticky-popup {
  position: fixed;
  bottom: 1.5%;
  right: 1.5%;
  width: 90%;
  max-width: 400px;
  padding: 20px;
  border-radius: 8px;
  z-index: 1050;
  background-color: #000;
  border: 2px solid #ffc107;
  color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
}

/* Close Button Container */
.sticky-popup-close {
  position: absolute;
  top: 5px;
  right: 10px;
}

/* Close Button Styles */
.sticky-popup-btn-close {
  font-size: 1.2rem;
  color: #ffc107;
  border: none;
  background: none;
  cursor: pointer;
}

/* Heading Styles */
.sticky-popup-heading {
  font-weight: bold;
  color: #ffc107;
  margin-bottom: 10px;
}

/* Description and Footer */
.sticky-popup-description {
  margin-top: 10px;
}

.sticky-popup-footer {
  margin-top: 20px;
}

/* Responsiveness */
@media screen and (max-width: 768px) {
  .sticky-popup {
    width: 90%;
    bottom: 5%;
    right: 5%;
  }

  .sticky-popup-btn-close {
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .sticky-popup {
    padding: 15px;
    font-size: 0.9rem;
  }

  .sticky-popup-heading {
    font-size: 1rem;
  }
}
