.loyalty-slider {
  width: 100%;
  margin-bottom: 10px;
}

.loyalty-slider::-webkit-slider-thumb {
  background: #ffd000;
}

.loyalty-slider::-moz-range-thumb {
  background: #ffd000;
}

.loyalty-slider::-ms-thumb {
  background: #ffd000;
}

.points-display {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.loyalty-legend {
  padding: 10px;
  border-radius: 8px;
  font-size: 1rem;
}

.loyalty-legend p {
  margin: 5px 0;
}
