#terms-conditions {
  padding: 10px 40px;
  margin: 10px 40px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #ffd000 !important;
}

.last-update {
  font-size: 12px;
  color: #888;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  #terms-conditions {
    padding: 10px 20px;
    margin: 10px 20px;
  }

  #title {
    font-size: 18px;
  }

  .last-update {
    font-size: 10px;
  }
}
