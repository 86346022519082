#sourceBtnContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  overflow-x: auto;
}

.sourceIcon {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  vertical-align: middle;
}

button.sourceBtn {
  background-color: black;
  border: 1px solid #ffffff;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

button.sourceBtn:hover {
  background-color: black !important;
  color: #ffd000 !important;
  border: 1px solid #ffd000 !important;
}

button.sourceBtn:hover img {
  filter: brightness(1.2);
}

button.sourceBtn.active {
  background-color: black !important;
  border: 1px solid #ffd000;
}

@media only screen and (max-width: 768px) {
  button.sourceBtn {
    font-size: 12px;
  }

  .sourceIcon {
    width: 16px;
    height: 16px;
  }
}
