#search-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

#search-btn {
  width: min-content;
}

.store-label {
  font-size: 1rem !important;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cart-link {
  text-decoration: none;
  color: #ffd000;
}

.cart-icon {
  color: #ffd000;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.cart-count {
  margin-left: 0.5rem;
}

.search-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  margin: 0.5rem 0;
}

.search-input {
  flex-grow: 1;
  margin-bottom: 0;
}

@media only screen and (max-width: 768px) {
  .cart-icon {
    width: 24px;
    height: 24px;
  }

  .cart-count,
  .search-input,
  #search-btn {
    font-size: 14px;
  }
}
