.checkoutDeliveryAddress {
  background-color: #222222;
  border: 1px solid #222222;
  padding: 1rem;
  border-radius: 10px;
}

.checkoutDeliveryAddress.active {
  border: 1px solid #ffd000;
}

.checkoutDeliveryAddress p {
  margin-bottom: 0px;
}

.cartItemQuantity {
  display: flex;
  gap: 10px;
  align-items: center;
}

.cartItemQuantity button {
  padding: 5px;
  background: #ffd000;
  color: black;
  border: none;
  border-radius: 10px;
  padding-bottom: 6px;
}

.cartItemQuantity button:hover {
  background: #ccaa00;
}

.cartItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #171717;
  margin-bottom: 0.5rem;
}

.cartItem p {
  margin-bottom: 0px;
}

.cartItem .checkoutProductImageContainer {
  width: 25%;
}

.cartItemContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 75%;
  padding: 0.5rem 0.75rem;
}

.cartItemHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cartItemName {
  font-weight: 600;
  margin-right: 1rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 90%;
}

.removeCartItemIcon {
  margin-top: 0.25rem;
}

.cartItemPrice {
  font-weight: 600;
  font-size: 20px;
}

.cartItemVariant {
  font-weight: 500;
  font-size: 14px;
  color: #999999;
}

.cartItemDelivery {
  font-weight: 500;
  font-size: 14px;
  color: #f8d640;
}

#cartOptionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.cartOptionItem {
  background-color: #222222;
  border: 1px solid #222222;
  width: 49%;
  padding: 1rem;
  border-radius: 10px;
}

.cartOptionItem.active {
  border: 1px solid #ffd000;
}

.cartOptionItem p {
  margin-bottom: 0px;
}

#cartPageContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#checkoutDetailsContainer {
  width: 70%;
}

#breakdownContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  width: 30%;
}

#breakdownDetails {
  width: 100%;
  border-radius: 10px;
  background-color: #222222;
  padding: 1rem;
  height: fit-content;
}

#cardExpiryInput {
  display: flex;
  flex-direction: row;
  border: 1px solid white;
  background-color: black !important;
  color: white;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  outline: none;
  height: 40px;
  width: 100%;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 16px;
  box-sizing: border-box;
  align-items: center;
}

#cardExpiryInput input {
  background-color: transparent;
  border: none;
  color: white;
  text-align: center;
}

#cardExpiryInput input:focus {
  box-shadow: none !important;
}

#cardExpiryInput input::placeholder {
  color: #999999;
}

#cardExpiryInput p {
  margin-bottom: 0px;
}

.bankAccountAddress {
  margin-bottom: 1rem;
  width: 49%;
  border: 1px solid #171717;
  padding: 1rem;
  border-radius: 10px;
}

.bankAccountAddress p {
  margin-bottom: 0px;
}

@media only screen and (max-width: 768px) {
  #checkoutDetailsContainer,
  #breakdownContainer {
    width: 100%;
  }

  .cartOptionItem {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .cartItemName {
    width: 80%;
    margin-right: 0.5rem;
  }

  .removeCartItemIcon {
    margin: 0;
    width: 25px;
    height: 25px;
  }

  #cartPageContainer,
  #cartOptionContainer,
  #bankAccountAddressesContainer,
  #productModalContainer {
    flex-direction: column !important;
  }

  .bankAccountAddress {
    width: 100%;
  }
}
