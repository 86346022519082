.loader {
  width: fit-content;
  font-weight: bold;
  font-size: 30px;
  background: linear-gradient(90deg, #fecf41 50%, #000 0) right/200% 100%;
  animation: l21 var(--animation-duration) infinite linear;
  padding: 20px;
  text-align: center;
}

.loader::before {
  content: var(--loader-text);
  color: #0000;
  background: inherit;
  background-image: linear-gradient(90deg, #000 50%, #fecf41 0);
  -webkit-background-clip: text;
  background-clip: text;
}

@keyframes l21 {
  100% {
    background-position: left;
  }
}

@media screen and (max-width: 768px) {
  .loader {
    font-size: 20px;
  }
}
